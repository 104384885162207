<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">{{item.code}}----参管项目信息</div>
    <div class="user-edit-content">
      <div class="query-data-area">
        项目名称：
        <Input v-model="filter.appName" placeholder="请输入项目名称" clearable style="width: 200px; margin-right: 10px;"></Input>
        组织名称：
        <Input v-model="filter.name" placeholder="请输入组织名称" clearable style="width: 200px; margin-right: 10px;"></Input>
        <Button type="primary" @click="search">查询</Button>
      </div>
      <div class="alarm-data-area" ref="table1">
        <vxe-table resizable border show-overflow row-key :row-config="{isHover: true}" :data="list" :scroll-y="{enabled: false}" height="500">
          <vxe-column field="isOwner" title="拥有者" width="70" header-align="center">
            <template #default="{ row }">
              <div :style="{color: row.isOwner ? 'blue' : 'green'}">{{row.isOwner ? '是' : '否'}}</div>
            </template>
          </vxe-column>
          <vxe-column field="enable" title="状态" width="60" header-align="center">
            <template #default="{ row }">
              <div :style="{color: row.enable ? 'green' : 'red'}">{{row.enable ? '启用' : '未启用'}}</div>
            </template>
          </vxe-column>
          <vxe-column field="appName" title="项目名称" header-align="center"></vxe-column>
          <vxe-column field="name" title="所属组织" width="200" header-align="center"></vxe-column>
          <vxe-column field="area" title="所在地区" width="100" header-align="center"></vxe-column>
          <vxe-column field="roleName" title="角色权限" header-align="center" width="180">
            <template #default="{ row }">
              {{row.roleNames.join(',')}}
            </template>
          </vxe-column>
          <vxe-column field="groupName" title="分区权限" header-align="center" width="180">
            <template #default="{ row }">
              {{row.groupNames.join(',')}}
            </template>
          </vxe-column>
          <vxe-column>
            <template #header>
              <Button v-if='funCodes(9901)' size="small" type="primary" style="margin-right: 5px" @click="handleAdd">加入项目</Button>
            </template>
            <template #default="{ row }">
              <Button v-if='!row.isOwner && funCodes(9942)' size="small" type="error" style="margin-right: 5px" @click="handleRemove(row)">退出项目</Button>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
      <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
      <ModalAddCust v-model="showCustModal" :item="editItem" @saved="custAdded" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalAddCust from './ModalAddCust'
export default {
  name: 'ModalUserEdit',
  components: {
    ModalAddCust
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      showCustModal: false,
      loading: false,
      editItem: {},
      filter: {
        userId: 0,
        name: '',
        appName: '',
        appType: '',
        isNewMode: false,
        index: 1,
        size: 20,
      },
      list: [],
      total: 0,
      custChanged: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('auth', ['appType', 'isNewMode', 'customerId']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.search();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    search: function () {
      this.filter.index = 1;
      this.getList();
    },
    getList: function () {
      this.filter.userId = this.item.id;
      this.filter.isNewMode = this.isNewMode;
      this.filter.appType = this.appType;
      this.$axios.post(`site/customer/QueryUserCusts`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.total = res.data.count;
        }
      });
    },
    handleAdd: function () {
      this.editItem = { user: this.item };
      this.showCustModal = true;
    },
    custAdded: function () {
      this.custChanged = true;
      this.getList();
    },
    handleRemove: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要退出项目[${params.appName}]吗?`,
        onOk: async () => {
          this.$axios.post(`site/customer/RemoveUserCust`, { custId: params.id, userId: this.item.id }).then(res => {
            if (res.code == 0) {
              this.custChanged = true;
              this.getList();
            }
          });
        }
      });
    },
    cancel() {
      this.showModal = false;
      if (this.custChanged) {
        this.$emit('saved');
      }
    },
  }
}
</script>
<style scoped>
.user-edit-content {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  /* border: solid 1px rgba(143, 138, 138, 0.452); */
  margin-bottom: 5px;
}
</style>